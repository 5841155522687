import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { DocumentUpload } from 'common/containers/document-upload';
import { useRouterQuery } from 'common/router';
import { Routes } from 'common/router/constants';
import { DocumentCategory } from 'entities/documents/documents-v2';

const getTitle = (category: DocumentCategory) =>
  ({
    [DocumentCategory.Identity]: 'Government-issued ID',
    [DocumentCategory.Medical]: 'Medical Documents',
    [DocumentCategory.Additional]: 'Additional Documents',
  })[category];

const getDescription = (category: DocumentCategory) =>
  ({
    [DocumentCategory.Identity]:
      'To verify that you’re legally able to purchase cannabis, we need a copy of your state or federally issued ID',
    [DocumentCategory.Medical]:
      'To verify that you’re eligible for medicinal cannabis, we need a copy of your medical card or recommendation',
    [DocumentCategory.Additional]:
      'Uploading documents like veteran, first responder, or student IDs may give you access to related discount programs',
  })[category];

const ProfileAddDocumentsPage: ApplicationPage = () => {
  const { category } = useRouterQuery();
  const documentCategory = category as DocumentCategory;

  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      header={{
        title: getTitle(documentCategory),
        description: getDescription(documentCategory),
        breadcrumbs: [
          { text: 'Profile', href: Routes.Profile },
          { text: 'Documents', href: Routes.ProfileDocuments },
          { text: getTitle(documentCategory) },
          { text: 'Add Documents' },
        ],
        defaultBackRoute: Routes.ProfileDocuments,
      }}
    >
      <DocumentUpload
        category={documentCategory}
        requiredOnly={false}
      />
    </Page>
  );
};

ProfileAddDocumentsPage.getMeta = ({ query: { documentCategory } }) => ({
  title: getTitle(documentCategory as DocumentCategory),
  description: getDescription(documentCategory as DocumentCategory),
});

ProfileAddDocumentsPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfileAddDocumentsPage;
